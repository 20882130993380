*{
  margin: 0px;
  padding: 0px;
}
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
img{
  display: block;
}

/* 公共动画 */
.RotateAnimation{
  animation: RotateAnimation 3s infinite linear;
}
@keyframes RotateAnimation{
  100%{transform:rotate(360deg);}
}

/* 重写部分样式 */
[custom_style=custom_style_radio] .adm-radio-checked .adm-radio-icon {
  border-color: #FE304F !important;
  background-color: #FE304F !important;
}
[custom_style=custom_style_radio] .adm-radio-content{
  padding-left: 1vw !important;
}
/* 隐藏滚动条 */
#root{
  box-sizing: border-box;
  height: 100vh;
  overflow: auto;
}

*::-webkit-scrollbar {
  display: none !important;
  width: 0 !important;
  height:0 !important;
  -ms-overflow-style: none !important;
  overflow: -moz-scrollbars-none !important;
}

::-webkit-scrollbar {
  display:none !important;
  width:0 !important;
  height:0 !important;
}

.adm-infinite-scroll{
  font-size: 3.73vw !important;
}
.adm-tabs-tab{
  color: #6B6C6C;
  padding: 2vw 0vw !important;
  line-height: 8vw !important;
}
.adm-tabs-tab-active{
  font-weight: bold;
}