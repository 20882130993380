.box{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
}
.loading_box{
  padding: 6.66vw 4.66vw;
  background-color:#000000;
  border-radius: 2vw;
  min-width: 22vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 10vh;
}
.loading_item{
  animation: loading 3s infinite linear;
  margin-bottom: 5vw;
}
@keyframes loading{
  100%{transform:rotate(360deg);}
}
.loading_text{
  color: #ffffff;
}